/* eslint-disable no-nested-ternary */

import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

export default function YesNoSwitch({ value = true, onChange = () => {}, disabled = false }) {
  // Only toggle if not disabled
  const toggle = () => {
    if (!disabled) {
      onChange(!value);
    }
  };

  return (
    <Box
      width="80px"
      height="32px"
      background="white"
      borderRadius="full"
      position="relative"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      border="1px solid #dcdcdc"
      onClick={!disabled ? toggle : undefined}
      opacity={disabled ? 0.6 : 1}
    >
      {/* The sliding knob */}
      <Box
        position="absolute"
        top="2px"
        left={value ? '2px' : 'calc(50% + 2px)'}
        width="calc(50% - 4px)"
        height="calc(100% - 4px)"
        background={disabled ? 'gray.400' : 'black'}
        borderRadius="full"
        transition="left 0.2s"
        zIndex={1}
      />

      {/* The labels container */}
      <Flex position="absolute" top="0" left="0" width="100%" height="100%" zIndex={2}>
        <Flex flex="1" align="center" justify="center">
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={value ? (disabled ? 'gray.500' : 'white') : 'gray.600'}
          >
            Si
          </Text>
        </Flex>
        <Flex flex="1" align="center" justify="center">
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={!value ? (disabled ? 'gray.500' : 'white') : 'gray.600'}
          >
            No
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
