/* eslint-disable import/no-extraneous-dependencies */

import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { PhoneNumberUtil } from 'google-libphonenumber';

import * as amplitude from '@amplitude/analytics-browser';
import { Identify, identify } from '@amplitude/analytics-browser';

import VisitorAPI from 'visitorapi';

import {
  Show,
  Box,
  Stack,
  Text,
  Image,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Input,
  Button,
  useToast,
  Checkbox,
  Flex,
} from '@chakra-ui/react';

import CustomLink from '../CustomLink/CustomLink';

import RegisterFormV2Card from './RegisterFormV3Card';

import { setCredentials } from '../../Redux/features/auth/authSlice';
import { useRegisterMutation } from '../../Redux/features/auth/authApiSlice';

import { formatDate } from '../../Utils/date';

import CRUWIIsologo from '../../Assets/Images/logo/cruwi-isotipo.svg';

import './RegisterFormV3.css';

// Creators images
import AidaImage from '../../Assets/Images/creators/aida.png';
import LuliImage from '../../Assets/Images/creators/luli.png';
import DelunaresImage from '../../Assets/Images/creators/delunares.png';
import LowisImage from '../../Assets/Images/creators/lowis.png';
import LacandeImage from '../../Assets/Images/creators/lacande.png';
import PaulaImage from '../../Assets/Images/creators/paula.png';
import SeleneImage from '../../Assets/Images/creators/selena.png';
import MartukiImage from '../../Assets/Images/creators/martuki.png';
import ByeByeImage from '../../Assets/Images/creators/byebye.png';
import MariaImage from '../../Assets/Images/creators/mariagi.png';

// Brands icon logos
import CashConvertersLogo from '../../Assets/Images/brands/cashconverters.png';
import DocsityLogo from '../../Assets/Images/brands/docsity.png';
import KnowunityLogo from '../../Assets/Images/brands/knowunity.png';
import PenguinLogo from '../../Assets/Images/brands/penguin.png';
import WaynaboxLogo from '../../Assets/Images/brands/waynabox.png';
import WegowLogo from '../../Assets/Images/brands/wegow.png';
import WuolahLogo from '../../Assets/Images/brands/wuolah.png';
import ZexelLogo from '../../Assets/Images/brands/zexel.png';

// Amplitude
const amplitudeEvent = new Identify();

// Data for cards
const creatorsData = [
  {
    id: 1,
    name: '@aidasanchezzz',
    followers: 723,
    earned: 70,
    image: AidaImage,
    colaborations: [PenguinLogo, WuolahLogo, CashConvertersLogo],
    otherCollabs: 5,
  },
  {
    id: 2,
    name: '@lulilifschitz2',
    followers: 961100,
    earned: 500,
    image: LuliImage,
    colaborations: [ZexelLogo, WuolahLogo, WaynaboxLogo],
    otherCollabs: 7,
  },
  {
    id: 3,
    name: '@_delunares',
    followers: 22300,
    earned: 300,
    image: DelunaresImage,
    colaborations: [WegowLogo, KnowunityLogo, ZexelLogo],
    otherCollabs: 15,
  },
  {
    id: 4,
    name: '@loouuwis',
    followers: 700,
    earned: 180,
    image: LowisImage,
    colaborations: [KnowunityLogo, CashConvertersLogo, WuolahLogo],
    otherCollabs: 12,
  },
  {
    id: 5,
    name: '@laacaandee',
    followers: 17870,
    earned: 130,
    image: LacandeImage,
    colaborations: [WuolahLogo, WaynaboxLogo, CashConvertersLogo],
    otherCollabs: 6,
  },
  {
    id: 6,
    name: '@paulaoterocolomer',
    followers: 1285,
    earned: 90,
    image: PaulaImage,
    colaborations: [ZexelLogo, CashConvertersLogo, DocsityLogo],
    otherCollabs: 10,
  },
  {
    id: 7,
    name: '@selenerodess',
    followers: 927,
    earned: 100,
    image: SeleneImage,
    colaborations: [WaynaboxLogo, PenguinLogo, WuolahLogo],
    otherCollabs: 4,
  },
  {
    id: 8,
    name: '@martukidegali',
    followers: 9169,
    earned: 60,
    image: MartukiImage,
    colaborations: [WaynaboxLogo, PenguinLogo, WuolahLogo],
    otherCollabs: 2,
  },
  {
    id: 9,
    name: '@byebyefifi',
    followers: 7798,
    earned: 100,
    image: ByeByeImage,
    colaborations: [PenguinLogo, WuolahLogo, CashConvertersLogo],
    otherCollabs: 1,
  },
  {
    id: 10,
    name: '@mariiagiil02_',
    followers: 2515,
    earned: 180,
    image: MariaImage,
    colaborations: [WuolahLogo, PenguinLogo, WaynaboxLogo],
    otherCollabs: 11,
  },
  {
    id: 11,
    name: '@aidasanchezzz',
    followers: 723,
    earned: 70,
    image: AidaImage,
    colaborations: [PenguinLogo, WuolahLogo, CashConvertersLogo],
    otherCollabs: 5,
  },
  {
    id: 12,
    name: '@lulilifschitz2',
    followers: 961100,
    earned: 500,
    image: LuliImage,
    colaborations: [ZexelLogo, WuolahLogo, WaynaboxLogo],
    otherCollabs: 7,
  },
  {
    id: 13,
    name: '@_delunares',
    followers: 22300,
    earned: 300,
    image: DelunaresImage,
    colaborations: [WegowLogo, KnowunityLogo, ZexelLogo],
    otherCollabs: 15,
  },
  {
    id: 14,
    name: '@loouuwis',
    followers: 700,
    earned: 180,
    image: LowisImage,
    colaborations: [KnowunityLogo, CashConvertersLogo, WuolahLogo],
    otherCollabs: 12,
  },
  {
    id: 15,
    name: '@laacaandee',
    followers: 17870,
    earned: 130,
    image: LacandeImage,
    colaborations: [WuolahLogo, WaynaboxLogo, CashConvertersLogo],
    otherCollabs: 6,
  },
  {
    id: 16,
    name: '@paulaoterocolomer',
    followers: 1285,
    earned: 90,
    image: PaulaImage,
    colaborations: [ZexelLogo, CashConvertersLogo, DocsityLogo],
    otherCollabs: 10,
  },
  {
    id: 17,
    name: '@selenerodess',
    followers: 927,
    earned: 100,
    image: SeleneImage,
    colaborations: [WaynaboxLogo, PenguinLogo, WuolahLogo],
    otherCollabs: 4,
  },
  {
    id: 18,
    name: '@martukidegali',
    followers: 9169,
    earned: 60,
    image: MartukiImage,
    colaborations: [WaynaboxLogo, PenguinLogo, WuolahLogo],
    otherCollabs: 2,
  },
  {
    id: 19,
    name: '@byebyefifi',
    followers: 7798,
    earned: 100,
    image: ByeByeImage,
    colaborations: [PenguinLogo, WuolahLogo, CashConvertersLogo],
    otherCollabs: 1,
  },
  {
    id: 20,
    name: '@mariiagiil02_',
    followers: 2515,
    earned: 180,
    image: MariaImage,
    colaborations: [WuolahLogo, PenguinLogo, WaynaboxLogo],
    otherCollabs: 11,
  },
];

// Desktop scroll animation
function VerticalScrollingCards({ creators }) {
  return (
    <Box position="relative" maxH="100dvh" overflow="hidden" width="100%" p="40px">
      <Stack spacing="20px" className="scrolling-cards">
        {creators.map((creator) => (
          <RegisterFormV2Card key={creator.id} data={creator} />
        ))}
      </Stack>
    </Box>
  );
}

// Mobile scroll animation
function HorizontalScrollingCards({ creators }) {
  return (
    <Box
      position="relative"
      maxH="100dvh"
      overflow="hidden"
      className="scrolling-cards-container-horizontal"
      width="100%"
      p="24px"
    >
      <Stack direction="row" spacing="20px" className="scrolling-cards-horizontal">
        {creators.map((creator) => (
          <RegisterFormV2Card key={creator.id} data={creator} />
        ))}
      </Stack>
    </Box>
  );
}

function levenshtein(a, b) {
  const matrix = [];
  let i;
  let j;

  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  // Increment along the first column of each row
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // Increment each column in the first row
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1),
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

function suggestDomainCorrection(email) {
  const domains = [
    'gmail.com',
    'hotmail.com',
    'hotmail.es',
    'yahoo.es',
    'yahoo.com',
    'icloud.com',
    'icloud.es',
    'outlook.com',
    'outlook.es',
  ];

  const emailParts = email.split('@');
  if (emailParts.length < 2) return null; // Not a valid email structure

  const domainPart = emailParts[1].toLowerCase();
  const suggestions = domains
    .map((domain) => ({
      domain,
      distance: levenshtein(domainPart, domain), // Calculate edit distance
    }))
    .filter((s) => s.distance <= 2 && s.distance > 0) // Suggest if within typo threshold of 2
    .sort((a, b) => a.distance - b.distance); // Sort by closest match

  return suggestions.length > 0 ? suggestions[0].domain : null;
}

// Funciones validación del móvil internacional
const phoneUtil = PhoneNumberUtil.getInstance();
const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export default function RegisterFormV3() {
  // Visitor data state and updates default
  const [visitorData, setVisitorData] = useState({});
  const [defaultCountry, setDefaultCountry] = useState('es');

  useEffect(() => {
    VisitorAPI('EPM28PBiCehmP7bSnxiN', (data) => {
      setVisitorData(data);
      if (data.countryCode) {
        const formattedCountryCode = data.countryCode.toLowerCase();
        setDefaultCountry(formattedCountryCode);
      }
    });
  }, []);

  // Params
  const [searchParams] = useSearchParams();
  const customerReferralEmail = searchParams.get('e');
  const referralCode = searchParams.get('referralCode');

  // From brands colab (Wuolah)
  const customerReferralBrandName = searchParams.get('b');

  // From ad/colabs campaigns (TikTok)
  const customRef = searchParams.get('ref');

  // Router
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();
  const [register, { isLoading }] = useRegisterMutation();

  // User data
  const [name, setName] = useState('');
  const handleChangeName = (event) => setName(event.target.value);

  const [email, setEmail] = useState(customerReferralEmail || '');
  const handleChangeEmail = (event) => setEmail(event.target.value);

  const [phone, setPhone] = useState('');

  const [tikTokUser, setTikTokUser] = useState('');
  const handleChangeTikTokUser = (event) => setTikTokUser(event.target.value);

  const [password, setPassword] = useState('');
  const handleChangePassword = (event) => setPassword(event.target.value);

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [gdprAccepted, setGdprAccepted] = useState(false);

  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
      duration: 1500,
    });
  };

  // Registration form submit and analytics
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name.length === 0) {
      showToast('El campo nombre no puede estar vacío', 'error');
      return;
    }

    if (email.indexOf('@') === -1 || email.indexOf('.') === -1) {
      showToast('El formato del email no es correcto', 'error');
      return;
    }

    if (!isPhoneValid(phone)) {
      showToast('El móvil no es correcto', 'error');
      return;
    }

    if (password.length < 4) {
      showToast('La contraseña es demasiado corta. Introduce al menos 4 caracteres', 'error');
      return;
    }

    if (gdprAccepted === false) {
      showToast('Debes dar el consentimiento al tratamiento de tus datos', 'error');
      return;
    }

    // Additional email domain check
    let finalEmail = email;
    const emailParts = email.split('@');
    const domainPart = emailParts[1];
    const isValidDomain = [
      'gmail.com',
      'hotmail.com',
      'hotmail.es',
      'yahoo.es',
      'yahoo.com',
      'icloud.com',
      'icloud.es',
      'outlook.com',
      'outlook.es',
      'protonmail.com',
      'proton.me',
    ].includes(domainPart.toLowerCase());

    if (!isValidDomain) {
      const suggestedDomain = suggestDomainCorrection(email);
      if (suggestedDomain) {
        finalEmail = `${emailParts[0]}@${suggestedDomain}`;
      }
    }

    const userData = {
      name: name.charAt(0).toUpperCase() + name.slice(1),
      email: finalEmail.toLowerCase(),
      phone,
      tikTokUser: tikTokUser.startsWith('@') ? tikTokUser.slice(1) : tikTokUser,
      password,
      brandReferral: customerReferralBrandName,
      gdprAccepted,
      sessionData: visitorData,
      referralCode,
    };

    try {
      const userRegisterData = await register(userData).unwrap();

      if (customRef === 'tiktok') {
        if (window.ttq) {
          window.ttq.track('CompleteRegistration');
        }
        amplitudeEvent.set('comesFrom', 'tiktokAd');
      }

      if (customerReferralBrandName) {
        amplitudeEvent.set('comesFrom', customerReferralBrandName);
      }

      amplitudeEvent.set('registrationDate', formatDate(new Date()));

      amplitude.setUserId(userRegisterData.user._id);
      amplitude.track('account_created');
      identify(amplitudeEvent);

      showToast('Bienvenido a CRUWI', 'success');
      dispatch(setCredentials(userRegisterData));
      navigate('/challenges');
    } catch (error) {
      const message =
        error.originalStatus === 409
          ? 'Ya existe un usuario con este email'
          : 'Hubo un problema con el registro';
      showToast(message, 'error');
    }
  };

  return (
    <Flex
      minH={['100vh', '100vh', '100vh', '100dvh', '100dvh']}
      flexDir={['column', 'column', 'column', 'row', 'row']}
      fontFamily="DM Sans"
      alignItems="center"
      justifyContent="space-between"
      bg="#FFFFF2"
    >
      <Box
        bg="#FFFFF2"
        p={[6, 6, 6, '40px', '80px', '100px', '120px']}
        width={['100%', '100%', '100%', '50%', '50%']}
      >
        <Image height="50px" mb={2} src={CRUWIIsologo} />
        <Text fontWeight="black" fontSize={['24px']}>
          Bienvenidx a CRUWI
        </Text>
        <Text mb={4} fontSize={['16px']} lineHeight={1.3}>
          Gana recompensas por hacer trends de tus canciones favoritas en TikTok e Instagram.
        </Text>
        <Stack>
          {/* Nombre */}
          <FormControl isRequired>
            <FormLabel mb="2px">Nombre</FormLabel>
            <Input
              size="sm"
              type="text"
              placeholder="Nombre"
              onChange={handleChangeName}
              value={name}
              borderRadius="8px"
              bg="white"
            />
          </FormControl>

          {/* Email */}
          <FormControl isRequired>
            <FormLabel mb="2px">Email</FormLabel>
            <Input
              size="sm"
              borderRadius="8px"
              type="email"
              placeholder="Tu mejor email"
              onChange={handleChangeEmail}
              value={email}
              bg="white"
            />
          </FormControl>

          {/* Móvil */}
          <FormControl isRequired>
            <FormLabel mb="2px">Móvil</FormLabel>
            <PhoneInput
              preferredCountries={['mx', 'ar', 'br', 'co', 'cl', 've']}
              defaultCountry={defaultCountry}
              className="cruwi-phone"
              value={phone}
              onChange={(p) => setPhone(p)}
            />
          </FormControl>

          {/* Usuario de TikTok */}
          <FormControl isRequired>
            <FormLabel mb="2px">Tu @ en TikTok</FormLabel>
            <Input
              size="sm"
              type="text"
              placeholder="@lolalolita"
              onChange={handleChangeTikTokUser}
              value={tikTokUser}
              borderRadius="8px"
              bg="white"
            />
          </FormControl>

          {/* Contraseña */}
          <FormControl isRequired>
            <FormLabel mb="2px">Contraseña</FormLabel>
            <InputGroup>
              <Input
                size="sm"
                borderRadius="8px"
                position="relative"
                type={show ? 'text' : 'password'}
                placeholder="Contraseña"
                onChange={handleChangePassword}
                value={password}
                bg="white"
              />
              <InputRightElement height="32px" mr="4px" w="60px">
                <Button size="xs" fontSize="12px" onClick={handleClick}>
                  {show ? 'Ocultar' : 'Mostrar'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Términos y condiciones */}
          <Checkbox
            size="lg"
            name="gdprAccepted"
            isChecked={gdprAccepted}
            onChange={(e) => setGdprAccepted(e.target.checked)}
          >
            <Text fontSize="12px">
              Doy mi consentimiento para que CRUWI me envíe comunicaciones. Al registrarte aceptas
              las{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.cruwi.com/condiciones-creators"
              >
                Condiciones de creador
              </a>{' '}
              y{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href="https://www.cruwi.com/condiciones-creators"
              >
                Política de Privacidad
              </a>
              .
            </Text>
          </Checkbox>
        </Stack>
        <Button
          isLoading={isLoading}
          fontFamily="DM Sans"
          onClick={handleSubmit}
          mt={8}
          w="full"
          bg="#000000"
          color="white"
          _hover={{
            bg: '#000000',
          }}
        >
          Crear cuenta
        </Button>
        <Text mt={6} color="black" fontSize="14px" textAlign="center" fontFamily="DM Sans">
          ¿Ya tienes cuenta?{' '}
          <CustomLink to="/login">
            <strong>Inicia sesión</strong>
          </CustomLink>
        </Text>
      </Box>
      <Show breakpoint="(max-width: 991px)">
        <Box bg="#EBD0FF" width={['100%', '100%', '100%', '50%', '50%']}>
          <HorizontalScrollingCards creators={creatorsData} />
        </Box>
      </Show>
      <Show breakpoint="(min-width: 992px)">
        <Box bg="#EBD0FF" width={['100%', '100%', '100%', '50%', '50%']}>
          <VerticalScrollingCards creators={creatorsData} />
        </Box>
      </Show>
    </Flex>
  );
}
