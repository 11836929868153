/* eslint-disable no-console */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Tag,
  TagLabel,
  TagCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  Flex,
  Input,
  Select,
  useMediaQuery,
  useToast,
  Button,
} from '@chakra-ui/react';

import StepIndicator from '../StepIndicator/StepIndicator';
import CustomChip from '../CustomChip/CustomChip';

import { setUserData } from '../../Redux/features/auth/authSlice';
import { useUpdateMutation, useSearchArtistsQuery } from '../../Redux/features/auth/authApiSlice';

// Tipo de música
const musicCategoriesMap = {
  Pop: 'Pop',
  'Hip Hop': 'Hip-Hop/Rap',
  Reggaeton: 'Reggaeton/Latin Urban',
  Rock: 'Rock',
  EDM: 'Electronic Dance Music (EDM)',
  'R&B': 'R&B',
  'K-Pop': 'K-Pop',
  Afrobeats: 'Afrobeats',
  Country: 'Country',
  'Indie/Alternativo': 'Indie/Alternative',
};

// Tipos de contenido
const contentTypesMap = {
  'Bailes-Coreografías': 'Dance',
  'Moda-Outfits': 'Fashion',
  'Trends (POV, Lip sync)': 'Trends',
  Lifestyle: 'Lifestyle',
  Libros: 'Books',
  Belleza: 'Beauty',
  Mascotas: 'Pets',
  'No estoy seguro': 'Not sure',
};

// Tipo de vestimenta
const clothesCategories = {
  Casual: 'casual',
  'Cayetanx-Pijx': 'preppy',
  'Urbano-Streetwear': 'urbanStreetwear',
  Otro: 'other',
};

const contentTypesOptions = Object.keys(contentTypesMap);
const musicOptions = Object.keys(musicCategoriesMap);
const clothesOptions = Object.keys(clothesCategories);

const mapSelectedCategoriesToEnglish = (selectedCategories, categoriesMap) =>
  selectedCategories.map((category) => categoriesMap[category] || category);

export default function CreatorInfoModal({ isOpen, close, userData }) {
  // Paso del form
  const [step, setStep] = useState(1);

  // Toast and show toast
  const toast = useToast();
  const showToast = (message, type) => {
    toast({
      title: `${message}`,
      status: type,
      isClosable: true,
    });
  };

  // Step Valdiations
  const [isValidStep1, setIsValidStep1] = useState(false);
  const [isValidStep2, setIsValidStep2] = useState(false);
  const [isValidStep3, setIsValidStep3] = useState(false);
  const [isValidStep4, setIsValidStep4] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const [update, { isLoading }] = useUpdateMutation();

  // Camnbio de tamaño para responsive
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');

  // Sexo
  const [selectedSex, setSelectedSex] = useState(userData?.gender);
  const handleSelectSex = (label) => {
    setSelectedSex(label);
  };

  // Fecha de nacimiento
  const [userBirthDate, setUserBirthDate] = useState(
    userData?.birthDate ? new Date(userData?.birthDate).toISOString().split('T')[0] : '',
  );

  const handleChangeUserBirthDate = (e) => {
    setUserBirthDate(e.target.value); // Keep it as a string in YYYY-MM-DD format
  };

  // País
  const [selectedCountry, setSelectedCountry] = useState(userData?.sessionData?.countryName || '');
  const handleSelectCountry = (e) => {
    setSelectedCountry(e.target.value);
  };

  // Estado para la búsqueda de artistas
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [artistQuery, setArtistQuery] = useState('');
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]); // Estado para los resultados mostrados

  // Hook para buscar artistas con RTK Query
  const { data: artistResults, isFetching: isSearching } = useSearchArtistsQuery(debouncedQuery, {
    skip: debouncedQuery.length < 3, // No buscamos si la consulta tiene menos de 3 caracteres
  });

  // Función para manejar la selección o eliminación de un artista
  const handleToggleArtist = (artistName) => {
    setSelectedArtists((prevArtists) => {
      if (prevArtists.includes(artistName)) {
        // Si ya está seleccionado, lo eliminamos
        return prevArtists.filter((artist) => artist !== artistName);
      }
      if (prevArtists.length < 3) {
        // Si no está seleccionado y hay menos de 3, lo agregamos
        return [...prevArtists, artistName];
      }
      return prevArtists;
    });

    // Limpiar el campo de búsqueda y los resultados mostrados después de seleccionar/deseleccionar un artista
    setArtistQuery('');
    setDisplayedResults([]); // Limpiar los resultados mostrados
  };

  // Elelgir estilo musical
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleSelectCategory = (label) => {
    if (selectedCategory.includes(label)) {
      setSelectedCategory((prevOptions) => prevOptions.filter((option) => option !== label));
    } else if (selectedCategory.length < 2) {
      setSelectedCategory((prevOptions) => [...prevOptions, label]);
    }
  };

  // Elegir tu tipo de contenido
  const [selectedContentType, setSelectedContentType] = useState([]);
  const handleSelectContentType = (label) => {
    if (selectedContentType.includes(label)) {
      setSelectedContentType((prevOptions) => prevOptions.filter((option) => option !== label));
    } else if (selectedContentType.length < 2) {
      setSelectedContentType((prevOptions) => [...prevOptions, label]);
    }
  };

  // Hace trends de baile
  const [makesDancingTrends, setMakesDancingTrends] = useState('');
  const handleMakesDancingTrends = (label) => {
    setMakesDancingTrends(label);
  };

  // Hace otros trends
  const [makesOtherTrends, setMakesOtherTrends] = useState('');
  const handleMakesOtherTrends = (label) => {
    setMakesOtherTrends(label);
  };

  // Elegir tu tipo de vestimenta
  const [selectedClotheStyle, setSelectedClotheStyle] = useState([]);
  const handleSelectedClotheStyle = (label) => {
    if (selectedClotheStyle.includes(label)) {
      setSelectedClotheStyle((prevOptions) => prevOptions.filter((option) => option !== label));
    } else if (selectedClotheStyle.length < 1) {
      setSelectedClotheStyle((prevOptions) => [...prevOptions, label]);
    }
  };

  // Si tiene hijos
  const [hasKids, setHasKids] = useState('');
  const handleHasKids = (label) => {
    setHasKids(label);
  };

  // Si tiene pareja
  const [hasCouple, setHasCouple] = useState('');
  const handleHasCouple = (label) => {
    setHasCouple(label);
  };

  // Si tiene mascota
  const [hasPet, setHasPet] = useState('');
  const handleHasPet = (label) => {
    setHasPet(label);
  };

  // Update del user
  const handleUpdateData = async () => {
    const favoriteGenders = mapSelectedCategoriesToEnglish(selectedCategory, musicCategoriesMap);
    const content = mapSelectedCategoriesToEnglish(selectedContentType, contentTypesMap);
    const clotheStyle = mapSelectedCategoriesToEnglish(selectedClotheStyle, clothesCategories)[0];

    // Datos a actualizar
    const updatedUserData = {
      segmentation: {
        contentType: {
          categories: content,
          likesDance: makesDancingTrends === 'Si',
          likesTrends: makesOtherTrends === 'Si',
        },
        clothingStyle: clotheStyle,
        musicalPreferences: {
          favoriteSingers: selectedArtists,
          favoriteGenres: favoriteGenders,
        },
        hasPartner: hasCouple === 'Si',
        hasPet: hasPet === 'Si',
        hasChildren: hasKids === 'Si',
        sociodemographic: {
          birthDate: userBirthDate,
          gender: selectedSex,
          country: selectedCountry,
        },
      },
    };

    try {
      const userUpdatedData = await update(updatedUserData).unwrap();
      showToast('Gracias por compartirlo :)', 'success');
      dispatch(setUserData(userUpdatedData));
    } catch (error) {
      showToast('Hubo un problema enviando la información.', 'error');
      console.log(error);
    }
  };

  // Validación de paso 1
  useEffect(() => {
    if (selectedSex !== '' && userBirthDate !== '' && selectedCountry !== '') {
      setIsValidStep1(true);
    } else {
      setIsValidStep1(false);
    }
  }, [selectedSex, userBirthDate, selectedCountry]);

  // Validación de paso 2
  useEffect(() => {
    if (selectedArtists.length > 0 && selectedCategory.length > 0) {
      setIsValidStep2(true);
    } else {
      setIsValidStep2(false);
    }
  }, [selectedArtists, selectedCategory]);

  // Validación de paso 3
  useEffect(() => {
    if (selectedContentType.length > 0 && makesDancingTrends !== '' && makesOtherTrends !== '') {
      setIsValidStep3(true);
    } else {
      setIsValidStep3(false);
    }
  }, [selectedContentType, makesDancingTrends, makesOtherTrends]);

  // Validación de paso 4
  useEffect(() => {
    if (selectedClotheStyle.length > 0 && hasKids !== '' && hasCouple !== '' && hasPet !== '') {
      setIsValidStep4(true);
    } else {
      setIsValidStep4(false);
    }
  }, [selectedClotheStyle, hasKids, hasCouple, hasPet]);

  // Lógica de debounce para la búsqueda
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQuery(artistQuery); // Actualizar el query con debounce
    }, 500); // Retraso de 500ms

    if (artistQuery === '') {
      setDisplayedResults([]); // Limpiar resultados si el input está vacío
    }

    return () => clearTimeout(timeoutId); // Limpiar el timeout si el usuario sigue escribiendo
  }, [artistQuery]);

  // Actualizar los resultados mostrados cuando artistResults cambia
  useEffect(() => {
    if (artistResults) {
      setDisplayedResults(artistResults?.data);
    } else {
      setDisplayedResults([]);
    }
  }, [artistResults]);

  // Step 1: Datos Socio demográficos
  const Step1 = (
    <Flex direction="column">
      <Box flex="1">
        {/* Indicador pantalla */}
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['1', '2', '3', '4']}
            setStep={setStep}
            isValidStep1={isValidStep1}
          />
        </Box>

        {/* Título pantalla */}
        <Box mb={5}>
          <Text fontSize="18px" mb={1} fontWeight="bold">
            Queremos conocerte mejor
          </Text>
          <Text fontSize="14px" lineHeight={1.3}>
            Para poder ofrecerte más y mejores retos, necesitamos que compartas con nosotros algunos
            datos y preferencias.
          </Text>
        </Box>

        {/* Sexo */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            Sexo
          </Text>
          <Box>
            <CustomChip
              label="Mujer"
              labelToSend="female"
              isSelected={selectedSex === 'female'}
              onSelected={handleSelectSex}
            />
            <CustomChip
              label="Hombre"
              labelToSend="male"
              isSelected={selectedSex === 'male'}
              onSelected={handleSelectSex}
            />
          </Box>
        </Box>

        {/* Fecha de nacimiento */}
        <Box mt={5}>
          <Text fontWeight="bold">Fecha de nacimiento</Text>
          <Input
            value={userBirthDate}
            onChange={handleChangeUserBirthDate}
            type="date"
            borderRadius={4}
            size="md"
            border="2px dotted #cccccc"
          />
        </Box>

        {/* País */}
        <Box mt={5}>
          <Text fontWeight="bold">País</Text>
          <Select
            placeholder="Selecciona tu país"
            value={selectedCountry}
            onChange={handleSelectCountry}
            borderRadius={4}
            size="md"
            border="2px dotted #cccccc"
          >
            <option value="Albania">Albania</option>
            <option value="Andorra">Andorra</option>
            <option value="Argentina">Argentina</option>
            <option value="Armenia">Armenia</option>
            <option value="Austria">Austria</option>
            <option value="Belgium">Bélgica</option>
            <option value="Bolivia">Bolivia</option>
            <option value="Brazil">Brasil</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Canada">Canadá</option>
            <option value="Chile">Chile</option>
            <option value="Colombia">Colombia</option>
            <option value="Costa Rica">Costa Rica</option>
            <option value="Croatia">Croacia</option>
            <option value="Czech Republic">República Checa</option>
            <option value="Denmark">Dinamarca</option>
            <option value="Dominican Republic">República Dominicana</option>
            <option value="Ecuador">Ecuador</option>
            <option value="El Salvador">El Salvador</option>
            <option value="Estonia">Estonia</option>
            <option value="Finland">Finlandia</option>
            <option value="France">Francia</option>
            <option value="Georgia">Georgia</option>
            <option value="Germany">Alemania</option>
            <option value="Gibraltar">Gibraltar</option>
            <option value="Greece">Grecia</option>
            <option value="Guatemala">Guatemala</option>
            <option value="Honduras">Honduras</option>
            <option value="Hungary">Hungría</option>
            <option value="Iceland">Islandia</option>
            <option value="Ireland">Irlanda</option>
            <option value="Italy">Italia</option>
            <option value="Lithuania">Lituania</option>
            <option value="Malta">Malta</option>
            <option value="Mexico">México</option>
            <option value="Monaco">Mónaco</option>
            <option value="Morocco">Marruecos</option>
            <option value="Netherlands">Países Bajos</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Norway">Noruega</option>
            <option value="Panama">Panamá</option>
            <option value="Paraguay">Paraguay</option>
            <option value="Peru">Perú</option>
            <option value="Poland">Polonia</option>
            <option value="Portugal">Portugal</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="Romania">Rumanía</option>
            <option value="Slovakia">Eslovaquia</option>
            <option value="Slovenia">Eslovenia</option>
            <option value="Spain">España</option>
            <option value="Sweden">Suecia</option>
            <option value="Switzerland">Suiza</option>
            <option value="Turkey">Turquía</option>
            <option value="Ukraine">Ucrania</option>
            <option value="United Kingdom">Reino Unido</option>
            <option value="United States">Estados Unidos</option>
            <option value="Uruguay">Uruguay</option>
            <option value="Venezuela">Venezuela</option>
          </Select>
        </Box>
      </Box>

      {/* Botón siguiente a paso 2 */}
      <Flex marginTop="30px">
        <Button
          onClick={() => setStep(2)}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isDisabled={!isValidStep1}
        >
          Siguiente
        </Button>
      </Flex>
    </Flex>
  );

  // Step 2: Datos Gustos Musicales
  const Step2 = (
    <Flex direction="column">
      <Box flex="1">
        {/* Indicador pantalla */}
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['1', '2', '3', '4']}
            setStep={setStep}
            isValidStep1={isValidStep2}
          />
        </Box>

        {/* Título pantalla */}
        <Box mb={5}>
          <Text fontSize="18px" mb={1} fontWeight="bold">
            Cuéntanos tus gustos musicales
          </Text>
          <Text fontSize="14px" lineHeight={1.3}>
            ¡Así podremos ofrecerte retos que se adapten a tus preferencias!
          </Text>
        </Box>

        {/* Artista favorito */}
        <Box mb={5} position="relative">
          <Text fontWeight="bold" lineHeight={1.1}>
            ¿Cuáles son tus artistas favoritos?
          </Text>
          <Text fontSize="12px" mb={2}>
            Elige máximo 3 opciones
          </Text>
          <Input
            placeholder="Escribe el nombre del artista"
            value={artistQuery}
            onChange={(e) => setArtistQuery(e.target.value)}
            borderRadius={4}
            size="md"
            border="2px dotted #cccccc"
          />
          {isSearching && (
            <Text mt={1} fontSize="12px">
              Buscando artista...
            </Text>
          )}
          {displayedResults && displayedResults.length > 0 && (
            <Box
              position="absolute" // Posición absoluta para flotar sobre otros elementos
              top="100%" // Colocar justo debajo del input
              left={0}
              right={0}
              zIndex={10}
              overflowY="auto"
              bg="white"
              border="1px solid #ccc"
              boxShadow="xl"
              mt={2}
              borderRadius="6px"
            >
              {displayedResults.slice(0, 5).map((artist, index) => (
                <Text
                  key={artist.id}
                  fontSize="14px"
                  p={1}
                  borderBottom={
                    index + 1 === displayedResults.length ? 'none' : '1px solid #e2e8f0'
                  }
                  onClick={() => handleToggleArtist(artist.name)}
                  cursor="pointer"
                  _hover={{ backgroundColor: '#f1f1f1' }}
                >
                  {artist.name}
                </Text>
              ))}
            </Box>
          )}
        </Box>

        {/* Selected Artists with pills */}
        {selectedArtists.length > 0 && (
          <Box mb={5}>
            <Text fontWeight="bold" lineHeight={1.1} mb={2}>
              Artistas seleccionados ({selectedArtists.length})
            </Text>
            <Box display="flex" flexWrap="wrap" gap={2}>
              {selectedArtists.map((artist) => (
                <Tag
                  size="md"
                  key={artist}
                  borderRadius="full"
                  variant="solid"
                  bg="black"
                  onClick={() => handleToggleArtist(artist)}
                >
                  <TagLabel>{artist}</TagLabel>
                  <TagCloseButton />
                </Tag>
              ))}
            </Box>
          </Box>
        )}

        {/* Género musical */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1}>
            Estilos de música favoritos
          </Text>
          <Text fontSize="12px" mb={2}>
            Elige máximo 2 opciones
          </Text>
          <Box mt={4}>
            {musicOptions.map((option) => (
              <CustomChip
                key={option}
                label={option}
                isSelected={selectedCategory.includes(option)}
                onSelected={handleSelectCategory}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {/* Botón siguiente a paso 2 */}
      <Flex marginTop="10px">
        <Button
          minW="100px"
          mr={2}
          onClick={() => setStep(1)}
          _hover={{ bg: 'white' }}
          fontFamily="DM Sans"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="white"
          color="black"
        >
          Atrás
        </Button>
        <Button
          onClick={() => setStep(3)}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isDisabled={!isValidStep2}
        >
          Siguiente
        </Button>
      </Flex>
    </Flex>
  );

  // Step 3: Tipo de contenido
  const Step3 = (
    <Flex direction="column">
      <Box flex="1">
        {/* Indicador pantalla */}
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['1', '2', '3', '4']}
            setStep={setStep}
            isValidStep1={isValidStep1}
          />
        </Box>

        {/* Título pantalla */}
        <Box mb={5}>
          <Text fontSize="18px" mb={1} fontWeight="bold">
            Tu contenido
          </Text>
          <Text fontSize="14px" lineHeight={1.3}>
            Nos encantaría saber más sobre el tipo de contenido que creas.
          </Text>
        </Box>

        {/* Tipo de contenido */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1}>
            La mayoría de tu contenido es de:
          </Text>
          <Text fontSize="12px" mb={2}>
            Elige mínimo 1 opción y máximo 2 opciones
          </Text>
          <Box mt={4}>
            {contentTypesOptions.map((option) => (
              <CustomChip
                key={option}
                label={option}
                isSelected={selectedContentType.includes(option)}
                onSelected={handleSelectContentType}
              />
            ))}
          </Box>
        </Box>

        {/* Hace trends de baile */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            ¿Haces trends de baile?
          </Text>
          <Box>
            <CustomChip
              label="Si"
              isSelected={makesDancingTrends === 'Si'}
              onSelected={handleMakesDancingTrends}
            />
            <CustomChip
              label="No"
              isSelected={makesDancingTrends === 'No'}
              onSelected={handleMakesDancingTrends}
            />
          </Box>
        </Box>

        {/* Hace otros tipos de baile */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            ¿Haces otros tipos de trends?
          </Text>
          <Box>
            <CustomChip
              label="Si"
              isSelected={makesOtherTrends === 'Si'}
              onSelected={handleMakesOtherTrends}
            />
            <CustomChip
              label="No"
              isSelected={makesOtherTrends === 'No'}
              onSelected={handleMakesOtherTrends}
            />
          </Box>
        </Box>
      </Box>

      {/* Botón siguiente a paso 3 */}
      <Flex marginTop="10px">
        <Button
          minW="100px"
          mr={2}
          onClick={() => setStep(2)}
          _hover={{ bg: 'white' }}
          fontFamily="DM Sans"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="white"
          color="black"
        >
          Atrás
        </Button>
        <Button
          onClick={() => setStep(4)}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isDisabled={!isValidStep3}
        >
          Siguiente
        </Button>
      </Flex>
    </Flex>
  );

  // Step 4: Otras preguntas
  const Step4 = (
    <Flex direction="column">
      <Box flex="1">
        {/* Indicador pantalla */}
        <Box width="100%" mx="auto">
          <StepIndicator
            currentStep={step}
            steps={['1', '2', '3', '4']}
            setStep={setStep}
            isValidStep1={isValidStep1}
          />
        </Box>

        {/* Título pantalla */}
        <Box mb={5}>
          <Text fontSize="18px" mb={1} fontWeight="bold">
            Y por último...
          </Text>
          <Text fontSize="14px" lineHeight={1.3}>
            Un poco más sobre ti para poder ofrecerte retos más personalizados.
          </Text>
        </Box>

        {/* Estilo de vestir */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1}>
            ¿Cuál es tu estilo de vestir?
          </Text>
          <Text fontSize="12px" mb={2}>
            Elige la opción que más te represente
          </Text>
          <Box mt={4}>
            {clothesOptions.map((option) => (
              <CustomChip
                key={option}
                label={option}
                isSelected={selectedClotheStyle.includes(option)}
                onSelected={handleSelectedClotheStyle}
              />
            ))}
          </Box>
        </Box>

        {/* Tiene hijos */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            ¿Tienes hijo/s?
          </Text>
          <Box>
            <CustomChip label="Si" isSelected={hasKids === 'Si'} onSelected={handleHasKids} />
            <CustomChip label="No" isSelected={hasKids === 'No'} onSelected={handleHasKids} />
          </Box>
        </Box>

        {/* Tiene pareja */}
        <Box mb={5}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            ¿Tienes pareja?
          </Text>
          <Box>
            <CustomChip label="Si" isSelected={hasCouple === 'Si'} onSelected={handleHasCouple} />
            <CustomChip label="No" isSelected={hasCouple === 'No'} onSelected={handleHasCouple} />
          </Box>
        </Box>

        {/* Tiene mascota */}
        <Box mb={3}>
          <Text fontWeight="bold" lineHeight={1.1} mb={1}>
            ¿Tienes mascota?
          </Text>
          <Box>
            <CustomChip label="Si" isSelected={hasPet === 'Si'} onSelected={handleHasPet} />
            <CustomChip label="No" isSelected={hasPet === 'No'} onSelected={handleHasPet} />
          </Box>
        </Box>
      </Box>

      <Box>
        <Text fontSize="14px" lineHeight={1.3} mb={2}>
          Revisa tus datos antes de finalizar, algunos no se podrán modificar más adelante.
        </Text>
      </Box>

      {/* Botón siguiente a paso 3 */}
      <Flex marginTop="10px">
        <Button
          minW="100px"
          mr={2}
          onClick={() => setStep(3)}
          _hover={{ bg: 'white' }}
          fontFamily="DM Sans"
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="white"
          color="black"
        >
          Atrás
        </Button>
        <Button
          onClick={() => handleUpdateData()}
          border="2px solid black"
          boxShadow="1px 1px 0px 0px #000"
          borderRadius="16px"
          bg="#EBD0FF"
          color="black"
          width="100%"
          isDisabled={!isValidStep4}
          isLoading={isLoading}
        >
          Finalizar
        </Button>
      </Flex>
    </Flex>
  );

  // Renderizado de los pasos
  const renderStep = () => {
    if (step === 1) return Step1;
    if (step === 2) return Step2;
    if (step === 3) return Step3;
    if (step === 4) return Step4;
    return null;
  };

  return (
    <Modal size={isLargerThan768 ? 'lg' : 'lg'} isOpen={isOpen} onClose={close} isCentered>
      <ModalOverlay />
      <ModalContent border="2px solid black" borderRadius="24px" m={5}>
        <ModalBody borderRadius="24px" fontFamily="DM Sans" bg="#feffef" p={4}>
          {renderStep()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
