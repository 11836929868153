import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectCurrentToken, selectCurrentUser } from '../Redux/features/auth/authSlice';

import RegisterFormV3 from '../Components/RegisterFormV3/RegisterFormV3';

export default function RegisterPage() {
  const token = useSelector(selectCurrentToken);
  const user = useSelector(selectCurrentUser);

  // Si ya tiene token y user, redirigimos a la página de retos (principal)
  if (token && user) {
    return <Navigate to="/" />;
  }

  return <RegisterFormV3 />;
}
