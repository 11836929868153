/* eslint-disable default-param-last */
export default function formatMoney(
  cents,
  locale = 'es-ES',
  currency = 'EUR',
  maximumFractionDigits,
) {
  const quantity = cents / 100;
  return quantity.toLocaleString(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits,
  });
}
